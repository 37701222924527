export const CAR_CLASSES = [
  {
    id: 1,
    class: 'economy',
    data: '/images/home-page/car-classes/kyte_economy.webp',
  },
  {
    id: 2,
    class: 'sedan',
    data: '/images/home-page/car-classes/kyte_sedan.webp',
  },
  {
    id: 3,
    class: 'suv',
    data: '/images/home-page/car-classes/kyte_suv.webp',
  },
  {
    id: 4,
    class: 'premium',
    data: '/images/home-page/car-classes/kyte_premium.webp',
  },
];
