import useTranslation from '@/hooks/use-translation';
import { Box, PillButton, Typography, useMedia } from '@drivekyte/ui';
import Image from 'next/image';

type DownloadSectionProps = {};

const DownloadSection = ({}: DownloadSectionProps) => {
  const { t } = useTranslation('pages.home-page.download');
  const media = useMedia();

  const handleDownload = () =>
    (window.location.href = 'https://drivekyte.com/download');

  return (
    <Box
      flexDirection="column"
      padding="$medium"
      gap="$medium"
      minHeight={80}
      width="100%"
      maxWidth={media.gtLg ? 1200 : 800}
      borderRadius="$medium"
      backgroundColor="$green700"
      alignItems="center"
      display="flex"
      $gtMd={{
        flexDirection: 'row',
        paddingVertical: '$small',
        paddingLeft: '$large',
        paddingRight: '$small',
      }}
    >
      <Box flex={1}>
        <Typography
          variant="headingSmall"
          color="$contentInversePrimary"
          marginBottom="$xTiny"
          textAlign="center"
          $gtMd={{ textAlign: 'left' }}
        >
          {t('headline')}
        </Typography>
        <Typography
          variant="paragraphSmall"
          color="$contentInversePrimary"
          textAlign="center"
          $gtMd={{ textAlign: 'left' }}
        >
          {t('description')}
        </Typography>
      </Box>
      {media.gtMd ? (
        <Image
          src="/images/home-page/download-section/qr-code.svg"
          width={80}
          height={80}
          alt="Download Kyte QRCode"
        />
      ) : (
        <Box>
          <PillButton
            alignSelf="center"
            variant="primary"
            onPress={handleDownload}
          >
            {t('cta')}
          </PillButton>
        </Box>
      )}
    </Box>
  );
};

export default DownloadSection;
