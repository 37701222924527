import SearchForm from '@/components/search-form';
import { Box, Typography } from '@drivekyte/ui';
import { StaticImageData } from 'next/image';

type SearchMobileProps = {
  headline: string;
  description: string;
  ctaText: string;
  backgroundImageSrc: StaticImageData | string;
  backgroundImageAltText: string;
  startAddressLabel?: string;
  startAddressPlaceholder?: string;
  startAddressModalTitle?: string;
  returnAddressLabel?: string;
  returnAddressPlaceholder?: string;
  returnAddressModalTitle?: string;
};

// Note: this component requires a dark background so that the light text is readable.

const SearchMobile = ({
  headline,
  description,
  ctaText,
  startAddressLabel,
  startAddressPlaceholder,
  startAddressModalTitle,
  returnAddressLabel,
  returnAddressPlaceholder,
  returnAddressModalTitle,
}: SearchMobileProps) => (
  <Box width="100%" rowGap="$xLarge">
    <Box>
      <Typography variant="headingXLarge" color="$contentInversePrimary">
        {headline}
      </Typography>
      <Typography variant="paragraphMedium" color="$contentInversePrimary">
        {description}
      </Typography>
    </Box>
    <SearchForm
      ctaText={ctaText}
      startAddressLabel={startAddressLabel}
      startAddressPlaceholder={startAddressPlaceholder}
      startAddressModalTitle={startAddressModalTitle}
      returnAddressLabel={returnAddressLabel}
      returnAddressPlaceholder={returnAddressPlaceholder}
      returnAddressModalTitle={returnAddressModalTitle}
    />
  </Box>
);

export default SearchMobile;
