import useTranslation from '@/hooks/use-translation';
import { Box, Typography } from '@drivekyte/ui';
import dynamic from 'next/dynamic';

const Search = dynamic(() => import('./components/search'));

type HeroDesktopProps = {
  headline?: string;
  ctaText?: string;
};

const HeroDesktop = ({ headline, ctaText }: HeroDesktopProps) => {
  const { t } = useTranslation('pages.home-page.hero');

  return (
    <Box width="auto" alignItems="center">
      <Box
        alignItems="center"
        width="100%"
        height={146}
        backgroundColor="$green700"
      >
        <Typography
          variant="headingXLarge"
          color="$contentInversePrimary"
          tag="h1"
        >
          {headline ?? t('headline')}
        </Typography>
      </Box>
      <Box marginTop={-36}>
        <Search ctaText={ctaText} />
      </Box>
    </Box>
  );
};

export default HeroDesktop;
