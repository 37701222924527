import useTranslation from '@/hooks/use-translation';
import { Box } from '@drivekyte/ui';
import CounterFreeImage from '../../../../../public/images/home-page/ValueProp_CounterFree.webp';
import NoLinesImage from '../../../../../public/images/home-page/ValueProp_Lines.webp';
import NewCarImage from '../../../../../public/images/home-page/ValueProp_NewCars.webp';
import PropItem from './components/prop-item';

const ITEMS = ['new-cars', 'counter-free-guarantee', 'skip-the-line'];
const ITEM_IMAGES = {
  'new-cars': NewCarImage,
  'counter-free-guarantee': CounterFreeImage,
  'skip-the-line': NoLinesImage,
};

const ValuePropsSection = () => {
  const { t } = useTranslation('pages.home-page.value-props');

  return (
    <Box width="100%">
      <Box
        gap="$xxLarge"
        display="flex"
        $gtMd={{ flexDirection: 'row', gap: '$large' }}
        marginTop={80}
        alignItems="center"
        justifyContent="center"
      >
        {ITEMS.map((item) => (
          <PropItem
            key={item}
            title={t(`items.${item}.title`)}
            description={t(`items.${item}.description`)}
            imageAlt={t(`items.${item}.title`)}
            imageSrc={ITEM_IMAGES[item]}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ValuePropsSection;
