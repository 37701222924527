import { Box, Typography, useMedia } from '@drivekyte/ui';
import Image from 'next/image';

type PropItemProps = {
  title: string;
  description: string;
  imageSrc: any;
  imageAlt: string;
};

const PropItem = ({
  imageAlt,
  title,
  description,
  imageSrc,
}: PropItemProps) => {
  const media = useMedia();

  const imageWidth = media.gtMd ? 380 : 480;
  const imageHeight = media.gtMd ? 250 : 225;

  return (
    <Box
      width="100%"
      maxWidth={imageWidth}
      $gtMd={{ width: 'calc((100% - 64px) / 3)' }}
    >
      <Box
        borderRadius="$large"
        marginBottom="$small"
        position="relative"
        height={imageHeight}
      >
        <Image
          src={imageSrc}
          alt={imageAlt}
          width={imageWidth}
          height={imageHeight}
          sizes={`(max-width: 768px) 480px, 380px`}
          priority
          style={{ borderRadius: 'inherit', width: '100%', height: 'auto' }}
        />
      </Box>
      <Typography variant="headingSmall" color="$contentPrimary">
        {title}
      </Typography>
      <Typography variant="paragraphSmall" color="$contentSecondary">
        {description}
      </Typography>
    </Box>
  );
};

export default PropItem;
