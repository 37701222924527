import request, { ApiResponse } from '@/utils/request';

export const enum MainPageTitleExperimentVariantsEnum {
  CONTROL = 'control',
  TREATMENT = 'treatment',
}

export type MainPageTitleExperimentResponse = {
  enabled: boolean;
  variant_attachment: {
    variant: string;
  };
  variant_name: MainPageTitleExperimentVariantsEnum;
};

export const requestExperimentAssignmentDetails = async (
  anonymousId: string,
) => {
  const { data: response } = await request<
    ApiResponse<MainPageTitleExperimentResponse>
  >(
    {
      url: 'v2/experiments/main_page_title/assign',
    },
    anonymousId,
  );

  return response.data;
};
