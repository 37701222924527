import useTranslation from '@/hooks/use-translation';
import { Box, Typography, getTokenValue, useMedia } from '@drivekyte/ui';
import Image from 'next/image';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CAR_CLASSES } from './car-classes.fixtures';

const CarClassesSection = () => {
  const { t } = useTranslation('pages.home-page.car-classes');
  const media = useMedia();

  const contentRef = useRef<HTMLDivElement>(null);
  const scrollTrackRef = useRef<HTMLDivElement>(null);
  const scrollThumbRef = useRef<HTMLDivElement>(null);
  const observer = useRef<ResizeObserver | null>(null);

  const [thumbWidth, setThumbWidth] = useState(94);

  const handleResize = (ref: HTMLDivElement, trackSize: number) => {
    const { clientWidth, scrollWidth } = ref;
    setThumbWidth(Math.max((clientWidth / scrollWidth) * trackSize, 94));
  };

  const handleThumbPosition = useCallback(() => {
    if (
      !contentRef.current ||
      !scrollTrackRef.current ||
      !scrollThumbRef.current
    )
      return;

    const { scrollLeft: contentLeft, scrollWidth: contentWidth } =
      contentRef.current;
    const { clientWidth: trackWidth } = scrollTrackRef.current;

    let newLeft = (+contentLeft / +contentWidth) * trackWidth;
    newLeft = Math.min(newLeft, trackWidth - 94);

    const thumb = scrollThumbRef.current;
    thumb.style.left = `${newLeft}px`;
  }, []);

  useEffect(() => {
    if (contentRef.current && scrollTrackRef.current) {
      const ref = contentRef.current;
      const { clientWidth: trackSize } = scrollTrackRef.current;

      observer.current = new ResizeObserver(() => {
        handleResize(ref, trackSize);
      });

      observer.current.observe(ref);
      ref.addEventListener('scroll', handleThumbPosition);
      return () => {
        observer.current?.unobserve(ref);
        ref.removeEventListener('scroll', handleThumbPosition);
      };
    }
  }, []);

  return (
    <Box>
      <style>
        {`
          .car-classes-section::-webkit-scrollbar {
            display: none;
          }

          .car-classes-section {
            overflow: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;
          }
        `}
      </style>
      <Typography variant="headingLarge" alignSelf="center">
        {t('headline')}
      </Typography>
      <Box
        paddingVertical="$large"
        flexDirection="row"
        display="flex"
        width="100vw"
        gap="$large"
        alignItems="flex-start"
        paddingHorizontal="$medium"
        overflow="scroll"
        $gtMd={{
          justifyContent: 'center',
        }}
        className="car-classes-section"
        ref={contentRef}
      >
        {CAR_CLASSES.map((carClass) => (
          <Box
            width={200}
            key={carClass.id}
            $gtSm={{ width: 216 }}
            $gtLg={{ width: 276 }}
            tabIndex={0}
          >
            <Image
              src={carClass.data}
              alt={t(`classes.${carClass.class}.title`)}
              width={200}
              height={133}
              {...(media.gtSm && {
                width: 216,
                height: 144,
              })}
              {...(media.gtLg && {
                width: 276,
                height: 184,
              })}
              style={{
                borderRadius: getTokenValue('$large', 'radius'),
              }}
            />
            <Typography
              variant="labelMedium"
              marginTop="$small"
              marginBottom="$xTiny"
            >
              {t(`classes.${carClass.class}.title`)}
            </Typography>
            <Typography variant="paragraphSmall">
              {t(`classes.${carClass.class}.description`)}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        width={240}
        height={2}
        backgroundColor="$borderDefault"
        alignSelf="center"
        ref={scrollTrackRef}
        display={thumbWidth < 240 ? 'block' : 'none'}
      >
        <Box
          position="absolute"
          width={thumbWidth}
          height={2}
          backgroundColor="$borderActive"
          ref={scrollThumbRef}
        />
      </Box>
    </Box>
  );
};

export default CarClassesSection;
