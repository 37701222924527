import { useMedia } from '@drivekyte/ui';
import HeroDesktop from './components/hero-desktop/hero-desktop';
import HeroMobile from './components/hero-mobile/hero-mobile';

type HeroSectionProps = {
  headline?: string;
  ctaText?: string;
  description?: string;
};

const HeroSection = (props: HeroSectionProps) => {
  const media = useMedia();

  return media.gtMd ? <HeroDesktop {...props} /> : <HeroMobile {...props} />;
};

export default HeroSection;
